import { Button, KeyboardAvoidingView, ScrollView, Pressable, useToast, View, Checkbox, Input, Box, Select, Toast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Linking, Platform, SafeAreaView, TouchableOpacity } from 'react-native';
import { auth } from '../../../constants/firebase/config';
import PhoneNumber from '../../shared/PhoneInput/index';
import Text from '../../shared/TextLabel';
import OtpInput from '../../shared/OTPinput';
import { shadow, UpdateUser } from '../../../helpers/helpers';
import Modal from '../../shared/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { SetScreenLoading, ToggleRedeemVoucher } from '../../../reducers/settings';
import { strings } from '../../../translations/index';
import { TextInput } from 'react-native-paper';
import Consent from '../../shared/Consent/Consent';
import { ArrowVector } from '../../../constants/imgs';
import Image from "../../shared/customImg";
import { functions, appCheck } from '../../../constants/firebase/config';

async function signInWithPhoneNumber(phoneNumber, verifier, customAuth) {
    let confirmation;
    if (customAuth) {
        let widgetId = Platform.OS === 'web' ? await verifier?.verify() : await appCheck().getToken(true);
        console.log("widgetIdwidgetId", widgetId);
        try {
            let SendOTP = await functions().httpsCallable('SendOTPCustom')({
                phoneNumber: phoneNumber,
                verifier: widgetId
            });
            let { status, code } = SendOTP?.data;
            if (status === 'error') {
                throw new Error(code)
            }
        } catch (error) {
            throw error
        }
        return widgetId
    }
    if (Platform.OS === 'web') {
        confirmation = await auth().signInWithPhoneNumber("+" + phoneNumber, verifier);
    } else {
        // auth().settings.appVerificationDisabledForTesting = true;
        confirmation = await auth().signInWithPhoneNumber(phoneNumber);
    }
    return confirmation
}

const CustomerLogin = ({ dismiss, fixed, callBack, dismissable = true }) => {
    const [state, setState] = useState({ step: 1 });
    const [verifier, setVerifier] = useState()
    const [terms, toggleTerms] = useState(false);
    const [customAuth, toggleCustomAuth] = useState(false);
    const [countdown, setCountdown] = useState(30);

    const [userInfo, setUserInfo] = useState({});
    const user = useSelector(state => state.user)
    const { ambassador } = useSelector(state => state.settings)
    const [loading, toggleLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch()
    const [promptView, togglePrompt] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if (Platform.OS === 'web' && window.document.getElementById('recaptcha-container')) {
                setVerifier(new auth.RecaptchaVerifier('recaptcha-container', {
                    // 'size': 'invisible',
                    'callback': function (response) {
                        // console.log("responseresponse", response);
                    }
                }))
            }
        }, 1000);
    }, [])
    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            clearInterval(timer);
            // Do something when countdown reaches zero
        }

        return () => clearInterval(timer);
    }, [countdown]);

    useEffect(() => {
        (async () => {
            if (state?.pin?.length === 6) {
                await confirmCode(state)
            }
        })()
    }, [state?.pin])

    useEffect(() => {
        if (user?.uid) {
            if (!user.displayName && ambassador) {
                setState({ ...state, step: 'userInfo' })
                return
            }
            if (ambassador) {
                dispatch(ToggleRedeemVoucher(true))
            }
            if (callBack) {
                callBack()
            }
            dismiss()
        }
    }, [user])

    async function confirmCode({ pin, phone }) {

        dispatch(SetScreenLoading(true))
        let { confirmation } = state

        switch (customAuth) {
            case true:
                try {
                    let VerifyOTP = await functions().httpsCallable('VerifyOTPCustom')({
                        phoneNumber: phone,
                        otp: pin,
                    });
                    let { error, token } = VerifyOTP?.data;
                    if (token) {
                        await auth().signInWithCustomToken(token);
                        return
                    }
                    if (error) {
                        dispatch(SetScreenLoading(false))
                        toggleLoading(false)
                        console.log("errorerrorerror", error);
                        toast.show({
                            title: 'Error',
                            description: error?.code,
                            placement: 'top'
                        })
                    }
                } catch (error) {
                    console.log("errorerror", error);
                    dispatch(SetScreenLoading(false))
                    toggleLoading(false)
                    console.log("errorerrorerror", error);
                    toast.show({
                        title: 'Error',
                        description: error?.message,
                        placement: 'top'
                    })
                }
                break;
            default:
                try {
                    await confirmation.confirm(pin);
                    // if (callBack) {
                    //     callBack()
                    // }
                    // dismiss()
                } catch (error) {
                    dispatch(SetScreenLoading(false))
                    toggleLoading(false)
                    console.log("errorerrorerror", error);
                    toast.show({
                        title: 'Error',
                        description: error?.message,
                        placement: 'top'
                    })
                }

                break;
        }


        return
        // functions().useFunctionsEmulator('http://localhost:5000/metadoc-app/us-central1/VerifyOTP')
        // switch (Platform.OS) {
        //     case 'web':
        //         try {
        //             let VerifyOTP = await functions().httpsCallable('VerifyOTP')({
        //                 phoneNumber: phone,
        //                 otp: pin,
        //             });
        //             let { error, token } = VerifyOTP?.data;
        //             if (token) {
        //                 await auth().signInWithCustomToken(token);
        //                 return
        //             }
        //             if (error) {
        //                 dispatch(SetScreenLoading(false))
        //                 toggleLoading(false)
        //                 console.log("errorerrorerror", error);
        //                 toast.show({
        //                     title: 'Error',
        //                     description: error?.code,
        //                     placement: 'top'
        //                 })
        //             }
        //         } catch (error) {
        //             console.log("errorerror", error);
        //             dispatch(SetScreenLoading(false))
        //             toggleLoading(false)
        //             console.log("errorerrorerror", error);
        //             toast.show({
        //                 title: 'Error',
        //                 description: error?.message,
        //                 placement: 'top'
        //             })
        //         }
        //         break;


    }
    if (promptView) {
        return <View height={Platform.OS === 'web' ? '100vh' : '100%'}>
            <SafeAreaView />
            <View flexDirection={'row'} paddingX={4} paddingY={Platform.OS === 'web' ? 4 : 0} alignItems='center'>
                <TouchableOpacity
                    onPress={() => togglePrompt(false)}
                    hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                    w={10}>
                    <Image
                        uri={ArrowVector}
                        // rotate={locale === 'ar'}
                        width={20}
                        height={20}
                        svg
                        fill='#0F172A'
                    />
                </TouchableOpacity>
            </View>
            <Consent readOnly type={promptView} />
        </View>

    }

    return (

        <Modal fixed={true} dismiss={dismissable ? dismiss : () => { }} size={'full'} >
            <KeyboardAvoidingView
                behavior={'padding'}
                position={'absolute'}
                borderTopRightRadius={8}
                borderTopLeftRadius={8}
                bottom={0}
                backgroundColor='#F5F7F8'
                w={'100%'}
            >

                <ScrollView
                    borderTopRightRadius={8}
                    borderTopLeftRadius={8}
                    bottom={0}
                    // background={'#FFFFFF'}
                    paddingY={8}
                >
                    <View paddingX={4}>
                        <Text fontFamily='bold' style={{ fontSize: 24 }}>
                            {state.step === 1 ?
                                strings?.['Welcome']
                                : state.step === 2 ?
                                    strings?.['Phone verification']
                                    : strings?.["Welcome"]}
                        </Text>
                        <Text >
                            {state.step === 1 ?
                                strings?.['Please enter your mobile number to login']
                                : state.step === 2 ?
                                    strings?.['Enter your OTP code here']
                                    : strings?.["Please enter your contact details"]}
                        </Text>
                    </View>
                    <View paddingX={4} paddingTop={6} zIndex={99} paddingBottom={100}>
                        {
                            state.step === 1 ?
                                <>
                                    <View style={{ direction: 'ltr', zIndex: 1 }}>
                                        <PhoneNumber
                                            country={'ae'}

                                            regions={['middle-east', 'north-africa']}
                                            onChange={phone => setState({ ...state, phone: phone?.replace(/^0+/, '') })}
                                            onChangeFormattedText={phone => setState({ ...state, phone: phone?.replace(/^0+/, '') })}
                                        />
                                    </View>
                                    <View flexDirection={'row'} paddingY={4}>
                                        <Checkbox onChange={(val) => toggleTerms(val)} width='100%'>
                                            <TouchableOpacity alignItems="center" width='100%'>
                                                <Text fontSize={11} paddingX={2} width='80%'>
                                                    {strings?.formatString(strings?.['I have read and agree to the Metadoc'],
                                                        // <Text style={{ color: '#2498db' }} onPress={() => togglePrompt('privacy')} fontSize={11}>
                                                        <Text style={{ color: '#2498db' }} onPress={() => Linking.openURL("https://www.metadocapp.com/privacy-policy/").catch((err) => console.error('An error occurred', err))} fontSize={11}>
                                                            {strings?.["Privacy Policy"]}
                                                        </Text>
                                                        , <Text style={{ color: '#2498db' }} onPress={() => Linking.openURL("https://www.metadocapp.com/terms-and-conditions/").catch((err) => console.error('An error occurred', err))} fontSize={11}>
                                                            {strings?.["Terms of Use"]}
                                                        </Text>)}


                                                </Text>
                                            </TouchableOpacity>


                                        </Checkbox>
                                    </View>
                                </>

                                : state.step === 2 ?
                                    <View>

                                        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                                            <OtpInput
                                                state={state}
                                                onChange={(pin) => setState({ ...state, pin })}
                                            />

                                        </View>
                                        <View flexDirection={'row'} justifyContent={'center'} padding={8}>

                                            {
                                                countdown ?
                                                    <Text style={{ fontSize: 14, color: '#cacaca', fontWeight: '600' }}>Resend in {countdown}</Text>
                                                    :

                                                    <TouchableOpacity
                                                        style={{
                                                            width: 120
                                                        }}
                                                        onPress={async () => {
                                                            toggleCustomAuth(true)
                                                            let optimizedNumber = state.phone.replace(/\D/g, '');
                                                            setCountdown(30)
                                                            let confirmation = await signInWithPhoneNumber(optimizedNumber, verifier, true)
                                                            console.log("resend code");
                                                        }}
                                                    >

                                                        <Text style={{ textAlign: 'center', color: '#20A2DF', fontWeight: '600' }}> Resend code</Text>
                                                    </TouchableOpacity>
                                            }
                                        </View>
                                    </View>
                                    :
                                    <View style={{ width: '100%', padding: 4 }}>
                                        <View flexDirection={'row'} justifyContent='space-between' width='100%' marginBottom={2}>
                                            <Select
                                                minWidth="25%"
                                                backgroundColor={'#fff'}
                                                height={55}
                                                borderRadius={8}
                                                borderColor='#fff'
                                                defaultValue='male'
                                                onValueChange={itemValue => setUserInfo({ ...userInfo, gender: itemValue })}
                                                selectedValue={userInfo['gender']}
                                            >
                                                <Select.Item label="Mr" value="male" />
                                                <Select.Item label="Mrs" value="female" />
                                            </Select>

                                            <View height={55} borderRadius={8} width={'72%'} overflow='hidden'>
                                                <TextInput
                                                    onChangeText={(text) => setUserInfo({ ...userInfo, displayName: text })}
                                                    value={userInfo?.displayName}
                                                    theme={{
                                                        dark: true,
                                                        roundness: 8,
                                                        colors: { primary: '#334155', placeholder: 'lightgray', background: "#fff" }
                                                    }}
                                                    style={{ outline: 'none', fontSize: 14, height: 57, overflow: 'hidden', width: `100%`, backgroundColor: '#fff', }}
                                                    label={strings?.['yourName']}
                                                    outlineColor={'#334155'}
                                                    underlineColor='#transparent'
                                                    underlineColorAndroid='transparent'
                                                />
                                            </View>
                                        </View>
                                        {
                                            [{ key: 'email', title: 'yourEmail' }].map(r => <View key={r.key} borderRadius={8} height={55} overflow='hidden' style={{ marginBottom: 8 }}>
                                                <TextInput
                                                    onChangeText={(text) => setUserInfo({ ...userInfo, [r.key]: text })}
                                                    value={userInfo[r.key]}
                                                    theme={{
                                                        dark: true,
                                                        roundness: 8,
                                                        colors: { primary: '#334155', placeholder: 'lightgray', background: "#fff" }
                                                    }}
                                                    keyboardType='email-address'
                                                    style={{ outline: 'none', fontSize: 14, height: 57, overflow: 'hidden', borderRadius: 8, width: `100%`, backgroundColor: '#fff', }}
                                                    label={strings?.[r.title]}
                                                    outlineColor={'#334155'}
                                                    underlineColor='transparent'
                                                    underlineColorAndroid='transparent'
                                                />
                                            </View>)
                                        }
                                    </View>
                        }
                    </View>
                    {
                        // state.step === 1 ?
                        <View flexDirection={'row'} justifyContent={'center'} alignItems={'center'} padding={4}>
                            <View nativeID='recaptcha-container' />

                        </View>
                        // :
                        // <View />
                    }

                    {state.step !== 2 ? <View paddingX={4}>
                        <Button
                            disabled={!terms}
                            borderRadius={8}
                            shadow={shadow({ color: '#00000040', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                            isLoading={loading}
                            _loading={{
                                backgroundColor: '#20A2DF',
                            }}
                            _spinner={{
                                color: '#fff'
                            }}
                            style={{
                                backgroundColor: terms ? '#20A2DF' : '#cacaca'
                            }}
                            isLoadingText=' '
                            height={50}
                            onPress={async () => {

                                // return
                                toggleLoading(true)
                                switch (state.step) {
                                    case 1:
                                        try {
                                            let confirmation = await signInWithPhoneNumber(state.phone, verifier)
                                            setState({ ...state, step: 2, confirmation });
                                            toggleLoading(false);
                                        } catch (error) {
                                            console.log("error", error);
                                            toggleLoading(false);
                                            toast.show({
                                                title: 'Error',
                                                description: error?.message,
                                                placement: 'top'
                                            })
                                        }
                                        break;
                                    case 'userInfo':
                                        if (!userInfo.displayName || !userInfo.email) {
                                            toggleLoading(false)
                                            return Toast.show({
                                                placement: 'top',
                                                title: 'Error',
                                                description: 'Please fill all fields',
                                            })
                                        }
                                        await dispatch(UpdateUser(userInfo))
                                        if (callBack) {
                                            callBack()
                                        }
                                        dispatch(ToggleRedeemVoucher(true))
                                        toggleLoading(false)
                                        dismiss()
                                        break
                                    default:
                                        break;
                                }

                                // if (state.step === 1) {

                                // } else {
                                //     await confirmCode(state.pin)
                                // }
                            }}>
                            <Text style={{ color: '#fff' }} fontFamily='medium'>
                                {strings?.["Continue"]}
                            </Text>
                        </Button>
                    </View> : <></>}
                </ScrollView>
            </KeyboardAvoidingView>

        </Modal >
    );
};

export default CustomerLogin;